.ValeursRsePage {
}

.values-section, .rse-section {
    margin: var(--spacing-xxl) 0;
}

/* ///////////////////////////// VALUES ////////////////////////*/

.nos-valeurs-image {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.value-item {
    display: flex;
    align-items: flex-start;
    margin-inline: 0;
    margin-block: 2rem;
    gap: var(--spacing-large);
}

.value-icon {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.value-icon svg {
    margin-top: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--primary-color);
}

.value-content {
    flex-grow: 1;
}

.value-content h3 {
    margin-top: 0;
    margin-bottom: var(--spacing-tiny);
    font-weight: 600;
    /* color: var(--primary-color); */
    text-transform: uppercase;
    font-size: var(--font-size-medium);
}

.value-content p {
    margin: 0;
    color: var(--text-secondary-color);
}


.value-content strong {
    font-weight: 600;
}

/* ///////////////////////////// RSE ////////////////////////*/
.rse-content {
    display: flex;
    gap: var(--spacing-xxl);
    align-items: flex-start;
}

.rse-items {
    flex: 2;
}

/* ///////////////////////////// RSE FOOTER ////////////////////////*/

.rse-image-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: var(--spacing-xxxl);
}

.rse-image-section img {
    max-width: 200px;
    height: auto;
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-medium);
}

.rse-link {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
}

.rse-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .rse-content {
        flex-direction: column;
    }

    .value-icon {
        width: 40px;
        height: 40px;
    }

    .value-icon svg {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 480px) {
    .value-item {
        gap: var(--spacing-medium);
    }
} 