.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
}


.modal-content {
    background-color: var(--bg-color);
    padding: var(--spacing-medium);
    border-radius: var(--border-radius);
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 80vw;
    max-height: 70vh;
    overflow-y: auto;
}


.modal-content h1 {
    margin-top: 0;
}

.modal-close {
    width: 2rem;
    height: 2rem;
    padding: 0;

    position: absolute;
    top: var(--padding-small);
    right: var(--padding-small);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    color: var(--text-color);
    background-color: transparent;
} 

.modal-close:hover {
    background-color: var(--bg-emphasis-color);
}

@media (max-width: 768px) {
    .modal-content {
        max-width: 90vw;
        max-height: 80vh;
    }
}
