.news-display {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.news-item {
    position: relative;
    width: 100%;
    min-width: 60vw;
    height: calc(100vh - var(--spacing-xxl));
    margin: 0 auto;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.3s ease, height 0.3s ease;
}

.media-element {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100vh;
}

video.media-element {
    object-fit: cover;
    object-position: center;
}


.media-element[style*="background-image"] {
    background-size: cover;
    background-position: center;
}

.news-overlay {
    position: absolute;   
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.5)
    );
    padding: var(--padding-medium);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
}

.news-display-content {
    position: relative;
    max-width: 60vw;
    min-width: 60vw;
    padding: var(--spacing-medium);
    padding-bottom: var(--padding-small);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-tiny);
    z-index: 11;
}

.news-display-title {
    margin: 0;
    color: var(--bg-color);
    font-size: var(--font-size-xxl);
}

.news-display-description {
    margin: 0;
    font-size: var(--font-size-small);
    color: var(--bg-color);
    font-weight: 300;
    text-align: justify;

}

.news-display-quote {
    margin: 0;
    font-size: var(--font-size-small);
    color: var(--bg-color);
    font-weight: 300;
}

.news-display-link-container {
    margin-top: var(--spacing-tiny);
    display: flex;
    justify-content: right;
}

.news-display-link-container > a {
    text-decoration: none;
    transition: text-decoration 0.3s ease;
    color: var(--bg-color);
    font-weight: 200;
}

.news-display-link-container > a:hover {
    text-decoration: underline;
}


/* Mode bouton */
/* .news-display-link-container > a {
    padding: var(--padding-small) var(--spacing-large);
    width: auto;
    background-color: var(--primary-color);
    border-radius: 200px;
    color: var(--bg-color);
    
    font-weight: normal;
    transition: background-color 0.3s ease;
} 

.news-display-link-container > a:hover {
    background-color: var(--button-hover);
} */

.news-display-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-display-arrow-container svg {
    fill: var(--bg-color) !important;
    width: 2rem;
    height: 2rem;
}
.news-display-arrow-container > button:hover {
    background-color: transparent !important;
}


@media (max-width: 1000px) {
    .news-display-content {
        max-width: 100%; 
    }
}


@media (max-width: 480px) {
    .news-display-title {
        font-size: 2rem;
    }
    
    .news-display-content {
        max-width: 100vw;
        min-width: 80vw;
    }
    
    /* Ajout d'une adaptation pour les vidéos sur mobile */
    .media-element {
        height: 100%;
        width: 100%;
        object-position: center;
    }
}

@keyframes loading {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(350%); }
}

.news-item.loading {
    opacity: 0;
}