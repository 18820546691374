/* Conteneur centré */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Cercle de chargement */
.spinner {
  border: 0.25rem solid rgba(0, 0, 0, 0.1);
  border-top: 0.25rem solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner.on-primary {
  border: 0.25rem solid rgba(255, 255, 255, 0.1);
  border-top: 0.25rem solid var(--bg-color);
}

/* Animation de rotation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}