/* Ajoutez ce CSS à votre fichier CSS */
.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 1rem;
}

.space-y-2>*+* {
  margin-top: 0.5rem;
}

.bold {
  font-weight: 700;
  color: var(--text-color);
}

.pi-8 {
  padding-inline: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.button-forgot-password,
.button-back-to-sign-in {
  width: 100%;
  border: none;
  margin-top: 0;
}

.button-sign-up {
  width: 100%;
  color: var(--primary-color);
  border-color: var(--primary-color);
}