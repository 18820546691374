
/* //////////////////// LOADING //////////////////// */

.loading-indicator-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    gap: var(--spacing-tiny);
    background-color: var(--dark-bg-color);
    z-index: 10000;
}

.loading-logo {
  width: 20rem;
  height: auto;
}
