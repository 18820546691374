@font-face {
  font-family: 'Barett-Street';
  src: url('./Barett-Street.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Acumin-Variable-Concept';
  src: url('./Acumin-Variable-Concept.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-VariableFont_wght.ttf') format('truetype');
}
  
@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
  font-style: italic;
}

/* @font-face {
  font-family: 'FiraSans';
  src: url('./FiraSans-Light.ttf') format('truetype');
}
   */