
.loading-icon-button:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}

.loading-icon-button:disabled {
  background-color: var(--bg-emphasis-color);
}

.loading-icon-button .spinner {
  border: 0.1rem solid transparent;
  border-top: 0.1rem solid var(--primary-color);
}

.loading-icon-button .spinner.on-primary {
  border: 0.1rem solid transparent;
  border-top: 0.1rem solid var(--bg-color);
}