
.loading-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-block: var(--spacing-tiny);
}

.loading-bar {
  width: 100%;
  height: 4px;
  background-color: var(--bg-color-secondary);
  border-radius: 2px;
  overflow: hidden;

}

.loading-bar::after {
  content: '';
  display: block;
  width: 40%;
  height: 100%;
  background-color: var(--primary-color);
  animation: loading 1.5s infinite ease-in-out;
}

.loading-text {
  font-weight: 300;
  color: var(--text-color);
}

.on-dark.loading-text {
  color: var(--bg-emphasis-color);
}

.on-primary.loading-bar::after {
  background-color: var(--bg-emphasis-color);
}

