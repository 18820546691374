/*//////////////////// FONT ////////////////////*/

/* @font-face {
    font-family: 'Acumin-Variable-Concept';
    src: url('./fonts/Acumin-Variable-Concept.ttf') format('truetype');
}

@font-face {
    font-family: 'Barett-Street';
    src: url('./fonts/Barett-Street.ttf') format('truetype');
} */

/*//////////////////// GENERAL STYLE ////////////////////*/

:root {
    --light-bleu-canard: #3c6d72;
    --bleu-canard: #00383D;
    --dark-bleu-canard: #00272b;
    --very-light-chataigne: #e2d2ce;
    --light-chataigne: #b89890;
    --chataigne: #743020;
    --dark-chataigne: #5f261a;
    --brique: #AD5544;
    --ivoire: #FFF5ED;
    --dark-ivoire: #E1D2C6;
    --light-gray: #b6b6b6;

    --primary-color: var(--brique);
    --secondary-color: var(--chataigne);
    /* --text-color: var(--bleu-canard); */
    --text-color: #383838;
    --text-secondary-color: #5a5a5a;
    /* --h1-color: var(--text-color); */
    --h1-color: #292929;
    /* --h2-color: var(--secondary-color); */
    --h2-color: var(--h1-color);
    /* --h3-color: var(--primary-color); */
    --h3-color: var(--text-color);
    --placeholder-text-color: var(--ivoire);
    /* --placeholder-text-color: var(--light-chataigne); */

    --bg-color: white;
    --bg-emphasis-color: var(--ivoire);
    --bg-emphasis-color-2: var(--dark-ivoire);
    --dark-bg-color: var(--bleu-canard);
    --dark-bg-emphasis-color: var(--dark-bleu-canard);

    --border-color: var(--light-chataigne);
    --border-secondary-color: #B9B9C7;
    --border-secondary-color-2: #EDEDF1;

    --blue-color: #3446ee;
    --error-color: #ca4339;
    --error-color-hover: #d0190f18;

    --on-primary-color: #ffffff;

    --button-hover: var(--dark-chataigne);
    --secondary-button-hover: var(--very-light-chataigne);
    --secondary-dark-button-hover: rgba(242, 241, 255, 0.103);

    --shadow-small: 0 0rem 2rem rgba(0, 0, 0, 0.05);
    --shadow-medium: 0 1rem 4rem rgba(0, 0, 0, 0.1);

    /* Variables de tailles */
    /* --padding-tiny: 4px;
    --padding-small: 8px;
    --padding-medium: 16px;
    --padding-large: 20px;
    --padding-xl: 24px;
    --padding-xxl: 32px; */

    --padding-tiny: 0.25rem; /* 4px */
    --padding-small: 0.5rem; /* 8px */
    --padding-medium: 1rem; /* 16px */
    --padding-large: 1.25rem; /* 20px */
    --padding-xl: 1.5rem; /* 24px */
    --padding-xxl: 2rem; /* 32px */

    --spacing-tiny: 1rem;
    --spacing-small: 1.5rem;
    --spacing-medium: 2rem;
    --spacing-large: 2.25rem;
    --spacing-xl: 3rem;
    --spacing-xxl: 4rem;
    --spacing-xxxl: 5rem;
    --spacing-jumbo: 7rem;

    /* --border-radius-small: 6px;
    --border-radius: 8px;
    --border-radius-large: 12px; */
    --border-radius-tiny: 0.25rem; /* 4px */
    --border-radius-small: 0.375rem; /* 6px */
    --border-radius: 0.5rem; /* 8px */
    --border-radius-large: 0.75rem; /* 12px */
    --border-radius-full: 9999px;
    /* --border-radius-small: 0;
    --border-radius: 0;
    --border-radius-large: 0;
    --border-radius-full: 0; */

    --font-size-tiny: 0.875rem; /* 14px */
    --font-size-small: 1rem; /* 16px */
    --font-size-medium: 1.20rem; /* 20px */
    --font-size-large: 1.5rem; /* 24px */
    --font-size-xl: 1.6rem; /* 32px */
    --font-size-xxl: 2.5rem; /* 48px */
    --font-size-xxxl: 4rem; /* 72px */
    --font-size-jumbo: 5rem; /* 96px */

    /* --acumin-font-family: 'Acumin-Variable-Concept', sans-serif; */
    --acumin-font-family: 'Montserrat', sans-serif;
    --barett-street-font-family: 'Barett-Street', sans-serif;

}


.bg-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

main {
  padding: var(--spacing-xxl) var(--spacing-jumbo);
  max-width: 1200px;
  margin: 0 auto;
}


body {
    font-family: var(--acumin-font-family), sans-serif;
    margin: 0;
    padding: 0;
    color: var(--text-color);
    height: 100vh;
    overflow-x: hidden;
    font-size: var(--font-size-medium);
    transition: background-color 0.3s, color 0.3s;

    background-color: var(--bleu-canard);
}

.App {
    background-color: var(--bg-color);
}

.app {
    display: flex;
    flex-direction: row;
    /* Sidebar + main content in a row */
    width: 100vw;
    /* Full viewport width */
    height: 100vh;
    /* Full viewport height */
    background-color: var(--bg-color);
}
/* 
.container {
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 var(--spacing-large);
    flex-direction: column;
} */


p {
    font-weight: 400;
    color: var(--text-color);
    line-height: 1.5;
    
}

p.secondary-text-color {
    color: var(--text-secondary-color);
}

h1 {
    color: var(--h1-color);
    font-weight: bold;
    font-size: 2.5rem;
    margin-top: var(--padding-xl);
    text-transform: uppercase;

}

h2 {
    color: var(--h2-color);
    font-size: 2rem;
    font-weight: 500;
    margin-block: 1rem;
    /* new */
    text-transform: uppercase;
}

h3 {
    color: var(--h3-color);
    font-size: var(--font-size-large);
    font-weight: 600;
}

h4 {
    color: var(--h1-color);
    font-size: var(--font-size-large);
    font-weight: 400;
}

svg {
    color: var(--text-color);
    fill: var(--text-color);
}

strong {
    font-weight: bold;
}

.quote {
    /* font-family: "Barett-Street"; */
    font-style: italic;
    font-weight: 300;
    color: var(--text-color);
    font-size: var(--font-size-xl);
    text-align: center;
    margin: var(--spacing-xl) 0;

}


div.dark,
section.dark {
    background-color: var(--dark-bg-color);
}

.dark p,
.dark h1,
.dark h2,
.dark h3 {
    color: var(--bg-color);
}

.dark a {
    color: var(--bg-color);
    font-weight: 500;
}

.dark svg {
    fill: var(--bg-color) !important;
}

.dark .icon-button:hover {
    background-color: #00000015;
}

a {
    color: var(--primary-color);
    text-decoration: none;
}

hr {
    border-top: 1px solid var(--light-gray);
    width: 100%;
}

ol {
    font-family: var(--acumin-font-family), sans-serif;
}

.absolute-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1000;
}

/*/////////////////////////////////// BUTTON STYLES ////////////////////*/

button {
    font-family: var(--acumin-font-family), sans-serif;
    font-weight: 500 !important;
    background-color: var(--primary-color);
    color: white;
    
    padding: 0 var(--padding-xxl);
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    font-size: var(--font-size-small) !important;
    transition: background-color 0.3s ease;
    height: 3rem;
}

button:hover {
    background-color: var(--button-hover);
}

button:disabled {
    cursor: not-allowed;
    /* Curseur indiquant qu'une action n'est pas autorisée */
}

.secondary-button {
    background-color: var(--bg-color);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.icon-text-button,
.load-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);
    border: 1px solid var(--primary-color);
    padding: var(--padding-medium) var(--padding-xxl);
    color: var(--primary-color);
    font-size: var(--font-size-small);
}

.text-icon-button p,
.icon-text-button p,
.load-more-button p {
    font-size: var(--font-size-small);
    color: var(--primary-color);
    margin: 0;
}

.icon-text-button svg,
.load-more-button svg {
    margin-right: 0.5rem;
    fill: var(--primary-color);
    width: 1.5rem;
    height: 1.5rem;
}

.text-icon-button svg {
    margin-left: 0.5rem;
    fill: var(--primary-color);
    width: 1.5rem;
    height: 1.5rem;
}

.secondary-icon-button {
    background-color: var(--bg-color);
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: var(--font-size-small);
}
.secondary-icon-button svg {
    fill: var(--primary-color);
}

.icon-button:hover,
.text-icon-button:hover,
.icon-text-button:hover,
.secondary-button:hover,
.load-more-button:hover {
    background-color: var(--bg-emphasis-color);
}


.icon-button {
    background: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: auto;
    border-radius: var(--border-radius-full);
    cursor: pointer;
}

.icon-button svg {
    padding: 0;
    margin: 0;
    fill: var(--text-color);
}

/* /////////////////////// FORM STYLE /////////////////////// */

.auto-resize-textarea {
    width: 100%;
    /* S'adapte à la largeur du conteneur */
    min-width: 200px;
    /* Largeur minimale */
    max-width: 100%;
    /* Pour éviter que le textarea dépasse le conteneur */
    min-height: 50px;
    /* Hauteur minimale */
    max-height: 50vh;
    /* Hauteur maximale */
    overflow: auto;
    /* Affiche les barres de défilement si nécessaire */
    box-sizing: border-box;
    /* Inclut la bordure et le padding dans la largeur et la hauteur */
    padding: var(--spacing-medium);
    resize: none;
}

.auto-resize-input {
    box-sizing: content-box;
    /* Important pour la largeur dynamique */
    padding: 5px;
    min-width: 100px;
    /* Largeur minimale */
    max-width: 100%;
    /* Pour éviter que l'input dépasse le conteneur */
    height: auto;
    /* Hauteur automatique */
    overflow: hidden;
    /* Pour éviter les barres de défilement */
    border: 1px solid #ccc;
    /* Bordure de l'input */
    font-size: 16px;
    /* Taille de la police */
}

.auto-resize-input::after {
    content: attr(value);
    visibility: hidden;
    position: absolute;
    white-space: pre;
    padding: 5px;
    font-size: 16px;
    /* Doit correspondre à la taille de la police de l'input */
}

input {
  font-family: var(--acumin-font-family), sans-serif !important;
  font-size: var(--font-size-medium) !important;
}

input[type="file"],
input[type="text"],
input[type="url"],
input[type="password"],
input[type="phone"],
input[type="company"],
input[type="city"],
input[type="number"],
textarea,
select {
    width: 100%;
    height: auto;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: var(--font-size-small);
    font-family: 'Red Hat Display', sans-serif;
    background-color: var(--input-bg);
    color: var(--text-color);
    transition: background-color 0.3s, color 0.3s;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 0;
    margin-right: var(--padding-medium);
    scale: 1.5;
}

button:focus-visible {
    outline: 1px auto var(--border-color);
    

}

select, 
textarea {
    box-sizing: border-box;
    padding: var(--padding-tiny) var(--padding-regular);

    font-size: var(--font-size-medium);
    font-family: var(--acumin-font-family), sans-serif;
    color: var(--text-color);

    border: 1px solid var(--border-secondary-color);
    background-color: var(--bg-color);
    border-radius: var(--border-radius);
}

input[type="text"]:focus-visible,
input[type="password"]:focus-visible,
input[type="number"]:focus-visible,
input[type="phone"]:focus-visible,
input[type="company"]:focus-visible,
input[type="city"]:focus-visible,
select:focus-visible, 
textarea:focus-visible {
    outline: 2px auto var(--primary-color);
}


/* ///////////// SignIn page /////////// */

@media (max-width: 1200px) {
    main {
        padding: var(--spacing-xl) var(--spacing-xl);
    }
}
  

@media (max-width: 1032px) {
    /* html {
        font-size: 16px;
    } */
}
  


@media (max-width: 550px) {
    html {
        font-size: 15px;
    }
    body, p {
        font-size: var(--font-size-small);
    }
    h3 {
        font-size: var(--font-size-medium);
    }
}
  

@media (max-width: 480px) {
    main {
        padding: var(--spacing-xl) var(--spacing-large);
    }
    .quote {
        font-size: var(--font-size-xl);
    }
}
  
  
.news-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.carousel-item {
    min-width: 300px; /* Ajustez selon vos besoins */
    height: 200px; /* Ajustez selon vos besoins */
    background-size: cover;
    background-position: center;
    position: relative;
    scroll-snap-align: start;
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    text-align: center;
}
  

/* /////////////////////// FORM STYLE /////////////////////// */

.contact-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-container {
    width: 100%;
    margin-bottom: var(--spacing-xl);
    max-width: 950px;
  }
  
  /* ////////////////////////// SECTIONS ////////////////////////// */
  .contact-coordinates-section {
    margin: var(--spacing-xxl) 0;
  }
  
  .contact-coordinates-section p {
    font-size: var(--font-size-medium);
  }
  
  .contact-form-section {
    margin: var(--spacing-xxl) 0;
  }
  
  /* ////////////////////////// CARTE ET ADRESSE ////////////////////////// */
  .contact-map-image {
    margin-block: var(--spacing-xl);
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }
  
  .contact-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    /* margin-bottom: var(--spacing-xl); */
  }
  
  .contact-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--padding-medium);
    margin-bottom: var(--padding-medium);
  }
  
  .contact-header h3 {
    margin: 0;
    padding: 0;
    transform: translateY(2px);
  }
  
  .contact-content {
    flex-grow: 1;
    width: 100%;
  }
  
  .contact-content h3 {
    margin-top: 0;
    margin-bottom: var(--spacing-tiny);
  }
  
  .contact-content p {
    margin: 0;
  }
  
  
  /* ////////////////////////// FORMULAIRE DE CONTACT ////////////////// */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-columns {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: 450;
    color: var(--text-color);
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  
  .form-group-checkbox {
    flex-direction: row;
    align-items: center;
  }
  
  .form-group-checkbox label {
    margin-bottom: 0;
  }
  
  /* ////////////////////////// CHAMPS DE FORMULAIRE ////////////////// */
  .form-group input[type="text"],
  .form-group input[type="password"],
  .form-group input[type="number"],
  .form-group input[type="url"],
  .form-group input[type="email"],
  .form-group input[type="company"],
  .form-group input[type="city"],
  .form-group input[type="phone"],
  .form-group textarea,
  .form-group select {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid var(--text-secondary-color);
    border-radius: var(--border-radius-tiny);
    /* border-radius: 0; */
    width: 100%;
    box-sizing: border-box;
    background-color: var(--bg-color);
  }
  
  .form-group input[type="text"]::placeholder,
  .form-group input[type="password"]::placeholder,
  .form-group input[type="number"]::placeholder,
  .form-group input[type="email"]::placeholder,
  .form-group input[type="phone"]::placeholder,
  .form-group input[type="company"]::placeholder,
  .form-group input[type="city"]::placeholder,
  .form-group textarea::placeholder {
    color: var(--text-secondary-color);
    font-weight: 300;
    font-family: 'Red Hat Display', sans-serif;
  }
  
  .form-group select {
    color: var(--text-color);
    font-family: 'Red Hat Display', sans-serif;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .form-group input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .form-group a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .form-group a:hover {
    text-decoration: underline;
    /* background-color: var(--secondary-button-hover); */
  }
  
  .form-submit {
    text-align: right;
  }
  
  /* ////////////////////////// MEDIA QUERIES ////////////////////////// */
@media (min-width: 768px) {
    .form-columns {
      flex-direction: row;
    }
  
    .form-column {
      gap: 1.5rem;
    }
}

@media (max-width: 1200px) {

    main {
        padding: var(--spacing-xxl) var(--spacing-xl);
    }
}
@media (max-width: 480px) {

    main {
        padding: var(--spacing-xxl) var(--spacing-small);
    }
}