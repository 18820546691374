/*//////////////////// REFERENCES SECTION STYLE ////////////////////*/
.references-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-medium);
    padding: var(--spacing-large);
    max-width: 1200px;
    margin: 0 auto;
}

.reference-card {
    position: relative;
    height: 300px;
    border-radius: var(--border-radius-small);
    overflow: hidden;
    cursor: pointer;
}

.reference-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.reference-card:hover .reference-card-image {
    transform: scale(1.05);
}

.reference-card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    align-items: flex-end;
    padding: var(--spacing-medium);
}

.reference-card-title {
    color: white;
    font-size: 1.5rem;
    margin: 0;
    padding-bottom: var(--padding-small);
    text-transform: uppercase;
    font-weight: 500;
}

.reference-item {
    background-color: var(--bg-color);
    border-radius: var(--border-radius);
    padding: var(--spacing-large);
    padding-inline: var(--spacing-jumbo);
}

/*//////////////////// REFERENCE HEADER ////////////////////*/

.reference-header {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: var(--spacing-medium);
}

.reference-title {
    /* position: relative;
    z-index: 1; */
    padding: var(--spacing-medium) 0;
    display: flex;
    justify-content: flex-start;
}

.reference-title h2 {
    font-size: 2.5rem;
    margin: 0;
}

.reference-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-large);
}

.reference-background-container {
    position: relative;
    width: 100%;
    height: 60vh;
    overflow: hidden;
}

.reference-background-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.reference-details {
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--spacing-medium);
    column-gap: var(--spacing-xxl);
    margin-bottom: var(--spacing-medium);
}

.reference-description {
    margin-top: var(--spacing-medium);
}


.reference-background-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.chevron {
    position: absolute;
    background: linear-gradient(-90deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
    border: none;
    padding: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 10;
    border-radius: 0;
    /* border-radius: 50%;
    top: 50%;
    transform: translateY(-50%); */
    height: 100%;
    width: 2.5rem;
}

.chevron svg {
    position: relative;
    fill: white;
    height: auto;
    rotate: 90deg;
}

.chevron.right {
    right: 0;
}

.chevron.left {
    left: 0;
}

.chevron:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* ///////////////////////*/

.reference-header {
    margin-bottom: var(--spacing-medium);
}

.reference-title-container,
.reference-title-button-container {
    margin-bottom: 0.5rem;
}

.reference-title-button-sub-container {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: var(--text-color) solid 1px;
    width: auto;
}

.reference-title-button-container {
    display: flex;
    justify-content: flex-end;
    gap: var(--padding-small);
    width: auto;
    margin-left: auto;
    margin-right: 0;
}

.reference-title-button-container button {
    padding: var(--padding-small);
    display: inline;
}

.reference-title-edit-button svg {
    fill: var(--blue-color);
}

.reference-title-delete-button svg {
    fill: var(--error-color);
}


.reference-location {
    font-size: var(--font-size-small);
    margin: var(--padding-tiny) 0 0 0;
}

/*//////////////////// REFERENCE PHOTOS ////////////////////*/

.reference-photos {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
    gap: var(--spacing-medium);
    margin: var(--spacing-medium) 0;
    margin-bottom: var(--spacing-xl);
}

.reference-photo {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: var(--border-radius-small);
}

.reference-info {
    margin-bottom: var(--spacing-tiny);
}

.reference-info-inline {
    display: flex;
    align-items: center;
    gap: var(--padding-medium);
    margin-bottom: var(--spacing-tiny);
}

.reference-icon {
    /* new */
    /* fill: var(--secondary-color); */
}

.reference-info-label {
    font-weight: 500;
    /* new */
    color: #797979;
}

.reference-info-content {
    color: var(--text-color);
    font-weight: 500;
    font-size: var(--font-size-small);
    white-space: pre-line;
}

.reference-description-info-content {
    font-size: var(--font-size-small);
    color: var(--header-text-color);
    font-weight: 500;

}

.reference-back-button {
    margin-inline: var(--spacing-medium);
    margin-top: var(--spacing-medium);
}

/*//////////////////// MEDIA QUERIES ////////////////////*/

@media (max-width: 1024px) {
    .reference-details {
        grid-template-columns: 1fr;
    }

    .reference-title h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .references-section {
        grid-template-columns: 1fr;
    }

    .reference-photos {
        grid-template-columns: 1fr;
    }

    .reference-item {
        padding: var(--spacing-medium);
    }

    .reference-title h2 {
        font-size: 1.2rem;
    }

    .reference-content {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .reference-header {
        display: block;
    }
    .reference-details-container {
        display: block;
    }

    .reference-title {
        padding: 0;
        margin: 0;

        /* position: absolute;
        top: auto;
        right: auto;
        left: auto;
        bottom: 0;
        height: auto;
        transform: none;
        padding-block: var(--spacing-medium);
        margin: 1.5rem; */
        
    }
    
    .reference-title h2 {
        padding: 0;
        margin: 0;
        height: 100%;
        font-size: 1.2rem;
    }   
    .reference-background-container {
    height: 30vh;
    }
}