/*//////////////////// DOWNLOAD SECTION MAIN STYLE ////////////////////*/

.import-section {
  display: flex;
  align-items: center;
  border: 1px solid var(--light-gray);

  border-radius: var(--border-radius);
  padding: var(--padding-tiny) var(--padding-large);
  cursor: pointer;
  transition: all 0.3s ease;
}

.import-section-container {
  width: 100%;
}

.import-section.drag-over {
  box-shadow: 0 0 12px var(--primary-color-10) inset;
  background-color: var(--bg-emphasis-color);
}

.import-section:hover {
  /* background-color: var(--bg-emphasis-color); */
  border-color: var(--header-text-color);
}


.import-section:hover p {
  color: var(--header-text-color) !important;
}

.import-section:hover svg {
  fill: var(--header-text-color);
  transition: all 0.3s ease;
}
.import-section svg {
  fill: var(--text-secondary-color);
  margin-right: var(--padding-large);
}

.import-section p {
  color: var(--text-secondary-color);
  transition: all 0.3s ease;
}


/* /////////////////// IMPORT FILE INFO ////////////////////*/

.import-file-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: var(--padding-medium) var(--padding-large);
  
}

.import-file-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.import-file-header * {
  flex: 0 0 auto;
}

.import-file-name {
  color: var(--header-text-color);
  flex: 1 1 auto;
}


.import-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}