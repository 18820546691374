.contact-main p {
  color: var(--text-secondary-color);
}

  /* ////////////////////////// TABLE DES HORAIRES ////////////////////////// */
  .schedule-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 500px;
    margin-bottom: var(--spacing-xl);
  }
  
  .schedule-table td {
    padding: var(--padding-tiny) var(--padding-medium) var(--padding-tiny) 0;
    margin-inline: 20%;
  }
  
  .schedule-table td:first-child {
    min-width: 100px;
    text-align: left;
  }
  
  .schedule-table td:nth-child(2) {
    text-align: center;
  }
  
  .schedule-table td:last-child {
    text-align: right;
  }

  .contact-address-and-schedule {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: var(--spacing-large);
    /* gap: 2rem; */
  }
  
.contact-content strong {
  font-weight: 600;
}

  /* ////////////////////////// HORAIRES ////////////////////////// */
  .contact-schedule-items {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contact-schedule-container {
    width: 100%;
    max-width: 950px;
    margin: var(--spacing-xl) calc(var(--spacing-jumbo) + 1.75rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact-schedule-items h3 {
    color: var(--text-color);
  }


  .contact-coordinates-section .google-map iframe {
    width: 100%;
    height: 60vh;
  }

/* ////////////////////////// MEDIAS ////////////////////////// */

@media (max-width: 1200px) {
  .contact-schedule-items {
    padding: var(--spacing-xl) calc(var(--spacing-xl) + 2rem);
  }
}

@media (max-width: 910px) {
  .contact-container {
    width: 90%;
  }
}

@media (max-width: 950px) {
  .contact-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 550px) {
  .contact-container h2 {
    font-size: var(--font-size-xl);
  }
  .contact-info p {
    font-size: var(--font-size-small);
  }
  .contact-info {
    margin-block: var(--spacing-large);
  }
}

@media (max-width: 480px) {
  .contact-schedule-items {
    padding: var(--spacing-xl) var(--spacing-large);
  }
}

