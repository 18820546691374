/*//////////////////// NEWSCREATIONPAGE MAIN STYLE ////////////////////*/
.NewsCreationPage {

}

.news-creation-page-header {
    margin-block: var(--spacing-xl);
}

.news-creation-form-section {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-emphasis-color);
    padding: var(--spacing-large);
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-large);
    gap: var(--spacing-medium);
}

.news-creation-form-section h3 {
    margin-block: 0;
}

.news-creation-form-section label {
    font-weight: 600;
}


.news-creation-field-description {
    font-size: var(--font-size-tiny);
    color: var(--text-secondary-color);
    margin: -0.25rem 0 0.5rem 0;
}

.news-creation-media-type-selector {
    display: flex;
    gap: var(--padding-medium);
    margin-bottom: var(--spacing-medium);
}

.news-creation-media-type-btn {
    flex: 1;
    padding: 0 var(--padding-medium);
    border-radius: var(--border-radius);
}

.news-creation-media-type-btn.active:hover {
    background-color: var(--primary-color);
}

.news-creation-media-input-group {
    display: flex;
    flex-direction: column;
    gap: var(--padding-medium);
    align-items: center;
}


.news-creation-file-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-medium);
    background-color: var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
}


.news-creation-file-preview span {
    font-size: var(--font-size-small);
}

.news-creation-remove-file {
    padding: var(--padding-tiny);
}

.news-creation-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--padding-medium);
    margin-top: var(--spacing-xl);
}



@media (max-width: 768px) {
    .news-creation-form-section {
        padding: var(--spacing-medium);
    }

    .news-creation-form-actions {
        flex-direction: column;
    }

    .news-creation-preview-button,
    .news-creation-submit-button {
        width: 100%;
    }
}