/*//////////////////// HEADER MAIN STYLE ////////////////////*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.3s;
  z-index: 1001;

}

.header.hidden {
  top: -100px; /* Ajustez la valeur en fonction de la hauteur de votre header */
}

.header.visible {
  top: 0;
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding-inline: 1.2rem;
  min-height: 4rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

/* Logo Container */
.logo-container {
  height: 2rem;
  align-items: center;
}

.logo {
  height: 100%;
  width: auto;
  margin-left: var(--padding-medium);
}

/* Menu Container for Larger Screens */
.menu-container {
  display: none; /* Updated: Hide menu by default */
}

.menu {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 0;
  margin-right: var(--spacing-medium);
}

.menu li {
  font-size: var(--font-size-small);
  color: var(--header-text-color);
  padding: var(--padding-large) var(--padding-medium);
  transition: background-color 0.3s;
  text-align: center;
  border-bottom: transparent 3px solid;
  transition: all 0.3s ease;
}

.active-menu-item {
  border-bottom: var(--border-color) 3px solid !important;
}

.active-mobile-menu-item > a {
  /* font-weight: 600 !important; */
  color: var(--dark-ivoire) !important;
  /* color: #383838; */
}

.menu li:hover {
  border-bottom: var(--primary-color) 3px solid;
}

#console-menu-item {
  color: var(--primary-color); /* Remplacez par le code de couleur bleu souhaité */
}


/* Contact Button Container for Larger Screens */
.button-container {
  display: none; /* Updated: Hide contact button by default */
}

/* Hamburger Menu for Mobile */
.hamburger-menu {
  background-color: transparent;
  display: flex;
  align-items: center; /* Vertically align icon in the header */
  justify-content: center; /* Center the icon horizontally */
  cursor: pointer;
  padding: 0.5rem; /* Add some padding for clickable area */
  border-radius: 100%;
  margin-block: var(--padding-small);
}

.hamburger-menu:hover {
  background-color: var(--dark-bg-emphasis-color);
}

.hamburger-icon {
  color: var(--bg-color);
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
}

.hamburger-icon svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--ivoire);
}



/* /////////////////////// Mobile Slide-in Menu /////////////////////// */
.mobile-menu {
  display: none; /* Updated: Hide mobile menu by default */
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  transition: transform 0.3s ease-in-out;
  z-index: 200;
  padding: 2rem;
}

.mobile-menu.open {
  display: block;
}

.mobile-menu-content {
  padding: 1rem 2rem;
  /* width: 80%; */
  border-radius: var(--border-radius-large);

  /* border: var(--border-color) 1px solid; */
  border: var(--light-gray) 1px solid;


  position: fixed;
  top: 2rem;
  right: 2rem;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

.mobile-menu li {
  padding-block: 0.5rem;
  width: 100%;
  height: 100%;
}

.mobile-menu a {
  display: block;
  color: var(--bg-color);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 300;
  width: 100%;
  height: 100%;
}


.mobile-menu a:hover {
  color: var(--dark-ivoire) !important;
}

.menu a:last-child {
  color: var(--bg-emphasis-color-2);
}

/* Overlay to close menu when clicking outside */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 199;
}



/* Media Queries for Larger Screens */
@media (min-width: 1160px) {
  /* Show menu and button on larger screens */
  .menu-container {
    display: block; /* Updated: Show menu */
  }

  .button-container {
    display: flex; /* Updated: Show contact button */
    justify-content: flex-end;
  }
  
  /* Hide hamburger menu and mobile menu on larger screens */
  .hamburger-menu {
    display: none; /* Updated: Hide hamburger menu */
  }
  
  
  .mobile-menu,
  .menu-overlay {
    display: none; /* Updated: Hide mobile menu and overlay */
}

}
@media (max-width: 500px) {
 .logo-container img {
    width: 80%;
  }
}