/*//////////////////// PRODUCT MAIN STYLE ////////////////////*/
.ProductPage {
    
}

.ProductPage main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding-inline: 0;
    max-width: 100%;
}

.product-page-container {
    margin-inline: var(--spacing-xxl);
}

/* //////////////////// PRODUCTS SECTION DESCRIPTION STYLE ////////////////////*/

.product-page-description-section {
    /* background-color: var(--bg-emphasis-color); */
    /* border-top: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray); */
    margin-top: var(--spacing-xxxl);
    width: 100%;
    padding: 5rem 4rem;
}

.product-page-description-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
}

.product-page-description-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.5rem;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    padding: 1.5rem;
    
    transition: box-shadow 0.3s ease;
}

.product-page-description-column:hover {
    box-shadow: 0 0 16px 0 #f151291e;
}

.product-page-description-grid p {
    margin: 0;
    padding: 0;
    font-size: var(--font-size-tiny);
}

p.product-page-description-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
}

.product-page-description-section-title {
    margin-top: 5rem;
}

/* Responsive design pour tablettes */
@media (max-width: 992px) {
    .product-page-description-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Responsive design pour mobiles */
@media (max-width: 768px) {
    .product-page-description-section {
        padding-inline: 2rem;
    }
    
    .product-page-description-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}

@media (max-width: 480px) {
    .product-page-description-section {
        padding: 3rem 1.5rem;
    }
}

/* //////////////////// CONTACT SECTION STYLE ////////////////////*/

.product-page-contact-section {
    margin-top: var(--spacing-xxxl);
    margin-bottom: var(--spacing-xl);
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: var(--spacing-large);
}

.product-page-contact-section h2 {
    color: var(--primary-color);
    margin: 0;
    text-transform: none;
}

.product-page-contact-section-button {
    align-self: flex-end;
}

@media (max-width: 768px) {
    .product-page-contact-section {
        width: 100%;
    }
    
    .product-page-description-section {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding-inline: 2rem;
    }

    
}

@media (max-width: 480px) {
    .ProductPage main {
        margin-inline: var(--spacing-small);
    }
    .product-page-container {
        margin-inline: 0;
    }
}