.amplify-button--primary {
  --amplify-internal-button-background-color: var(--primary-color);
  --amplify-internal-button-color: var(--bg-color);
}
:root {
  --amplify-colors-brand-primary-10: var(--primary-color) !important;
  --amplify-colors-brand-primary-80: var(--button-hover) !important;
  --amplify-colors-font-primary: var(--text-color) !important;
  --amplify-colors-font-secondary: var(--text-color) !important;
  --amplify-colors-background-primary: var(--bg-color) !important;
  --amplify-colors-background-secondary: var(--bg-emphasis-color) !important;
  --amplify-colors-background-tertiary: var(--bg-emphasis-color-2) !important;
  --amplify-colors-border-primary: var(--border-color) !important;
  --amplify-colors-border-secondary: var(--border-secondary-color) !important;
  --amplify-colors-error: var(--error-color) !important;
  --amplify-colors-error-background: var(--error-color-hover) !important;
  --amplify-components-tabs-item-active-border-color: var(--border-color) !important;
  --amplify-components-tabs-item-border-color: var(--secondary-button-hover) !important;
  --amplify-components-authenticator-router-border-color: transparent !important;
  --amplify-components-authenticator-modal-background-color: var(--bg-emphasis-color) !important;
}

div [data-amplify-container] .header {
  /* z-index:-20 !important; */
}

div [data-amplify-router] {
  border-radius:0 0 var(--border-radius) var(--border-radius) !important;
}

.amplify-button {
  --amplify-internal-button-background-color: var(--primary-color) !important;
}
.amplify-button--link:hover {
  --amplify-internal-button-background-color: var(--secondary-button-hover);
}

.amplify-button--link {
  --amplify-internal-button-color: var(--text-color) !important;
  font-weight: 600 !important;
}

[data-amplify-authenticator][data-variation=modal] {
  background-color: var(--bg-emphasis-color);
  position: absolute;
  top: 0;
  width: 100%;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: var(--bg-color);
  box-shadow: none;
  border: none;
  margin-block: 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.amplify-button--link {
  --amplify-internal-button-color: var(--text-color);
}

.amplify-tabs__item {
  border-color: transparent;
}

.amplify-tabs__list.amplify-tabs__list--top.amplify-tabs__list--equal {
  /* display: none; */
}

.amplify-tabs__item.amplify-tabs__item--active {
  color: var(--text-color);
  background-color: var(--bg-color) !important; 
  border-color: var(--primary-color);
}

.amplify-tabs__item {

  background-color: var(--bg-emphasis-color) !important;
}

button.amplify-tabs__item.amplify-tabs__item--active:hover {
  background-color: var(--bg-color) !important;
}
button.amplify-tabs__item:hover {
  background-color: var(--bg-emphasis-color-2) !important;

}

.amplify-button.amplify-field-group__control {
  color: var(--bg-color) !important;
  border: none !important;
}

.amplify-button.amplify-field-group__control:hover {
  background-color: var(--button-hover) !important;
}

[data-amplify-authenticator] [data-amplify-router] button[type="submit"]:hover {
  
  background-color: var(--button-hover) !important;
  border-color: var(--secondary-color);
  border-top: 2px solid var(--primary-color);
}

.amplify-button--primary {
  border: none !important; 
}

.amplify-button--primary:hover {
  background-color: var(--button-hover) !important;
}

[data-amplify-authenticator] [data-amplify-router] button:hover {
  background-color: var(--bg-emphasis-color-2);
}

.amplify-alert__body,
p[role="alert"] {
  font-size: var(--font-size-small);
}

input.amplify-input {
  font-family: var(--acumin-font-family), sans-serif !important;
  font-size: var(--font-size-small) !important;
}

.amplify-field-group__outer-end button {
  background-color: var(--bg-emphasis-color) !important;
}

.amplify-button--link {
  background-color: transparent !important;
}

.button-forgot-password,
.button-sign-up,
.button-back-to-sign-in {
  background-color: transparent !important;
  border: none !important;
  font-weight: 300 !important;
}

/* 

.amplify-tabs__item {
  padding: var(--padding-small) var(--padding-medium);
  background: var(--bg-color);
  border: 1px solid var(--border-secondary-color);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: 'Red Hat Display', sans-serif;
  font-size: var(--font-size-small);
}z

.amplify-tabs__item--active {
  background: var(--primary-color);
  color: var(--on-primary-color);
  border-color: var(--primary-color);
}

.amplify-input {
  padding: var(--padding-small);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  width: 100%;
  font-size: var(--font-size-small);
  font-family: 'Red Hat Display', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.amplify-button {
  background: var(--primary-color);
  color: var(--on-primary-color);
  border: none;
  padding: var(--padding-small) var(--padding-large);
  font-size: var(--font-size-small);
  font-family: 'Red Hat Display', sans-serif;
  border-radius: 200px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.amplify-button:hover {
  background-color: var(--button-hover);
}

.amplify-button:hover {
  background-color: transparent;
}
.amplify-button--link {
  background: transparent;
  color: var(--primary-color);
  text-decoration: underline;
  padding: 0;
  margin-top: var(--spacing-small);
  font-size: var(--font-size-small);
} */