.quantity-control-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.quantity-control-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
}
.quantity-control-value {
    min-width: 20px;
    width: 80px !important;
    text-align: center;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 4px;
    background-color: var(--bg-emphasis-color);
    transition: background-color 0.2s ease;
}

.quantity-control-value:hover {
    background-color: var(--bg-emphasis-color-2);
}

.quantity-control-input {
    width: 80px !important;
    font-size: var(--font-size-small) !important;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: center;
  }
  

@media (max-width: 480px) {
    
    .quantity-control-input,
    .quantity-control-value {
        width: 50px !important;
    }
    .quantity-control-row {
        gap: 0;
    } 
}

