/*//////////////////// REFERENCECREATIONPAGE MAIN STYLE ////////////////////*/
.ReferenceCreationPage {
    background-color: var(--bg-color);
}

.reference-creation-page-header {
    margin-block: var(--spacing-xl);
}

.reference-creation-form-section {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-emphasis-color);
    padding: var(--spacing-large);
    border-radius: var(--border-radius);
    margin-bottom: var(--spacing-large);
    gap: var(--spacing-medium);
}

.reference-creation-form-section h3 {
    margin-block: 0;
}

.reference-creation-form-section label {
    font-weight: 600;
}

.reference-creation-field-description {
    font-size: var(--font-size-tiny);
    color: var(--text-secondary-color);
    margin: -0.25rem 0 0.5rem 0;
}

.reference-photos-container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-medium);
    margin-top: var(--padding-medium); */
}

.reference-photo-upload {
    flex: 1;
}

.reference-creation-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--padding-medium);
    margin-top: var(--spacing-xl);
}

@media (max-width: 768px) {
    .reference-creation-form-section {
        padding: var(--spacing-medium);
    }

    .reference-photos-container {
        grid-template-columns: 1fr;
    }

    .reference-creation-form-actions {
        flex-direction: column;
    }

    .reference-creation-preview-button,
    .reference-creation-submit-button {
        width: 100%;
    }
}