.not-found-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.not-found-container h1 {
    font-size: 6rem;
    margin: 0;
    color: var(--primary-color);
}

.not-found-container h2 {
    font-size: 2rem;
    margin: 0;
    color: var(--header-text-color);
    margin-bottom: 2rem;
}



.ocean {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #0099ff; */
}
