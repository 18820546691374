.ProductDetailPage {
    width: 100%;
}

.ProductDetailPage main {
    padding: 0;
    width: 100%;
    padding-bottom: calc(80px + var(--spacing-xxl));
}

.ProductDetailPage section {
    margin-inline: var(--spacing-large);
}

/* ///////////////////////////// PRODUCT TYPE BANNER ///////////////////////////// */

.product-detail-product-type-banner {
    margin-top: var(--spacing-xxl);
    /* background: linear-gradient(to top, var(--primary-color), var(--secondary-color)); */
    /* background-color: var(--bg-emphasis-color); */
    /* border-radius: 0 0 var(--border-radius-large) var(--border-radius-large); */
    border-bottom: 1px solid var(--light-gray);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-detail-banner-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-tiny);
    padding: var(--spacing-tiny);
    margin: 0;

    background-color: transparent;
    height: auto;
}

.product-detail-banner-button:hover {
    background-color: transparent;
}

.product-detail-banner-arrow-container svg {
    width: 2rem;
    height: 2rem;
}

.product-detail-banner-arrow-container.open svg {
    transform: scaleY(-1);
}

.product-detail-banner-arrow-container .icon-button:hover {
    background-color: transparent;
}

/* ///////////////////////////// PRODUCT TYPE LIST ///////////////////////////// */

.product-detail-banner-type-list {
    will-change: transform;

    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease; /* Transition fluide */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-tiny);
    padding-inline: var(--spacing-tiny);
}

.product-detail-banner-type-list.open {
    max-height: 440px; /* Ajuster selon le contenu attendu */
    margin-bottom: var(--spacing-small);
    
}

.product-detail-banner-type-list-item {
    color: var(--text-color);
    transition: all 0.3s ease-out;
}
.product-detail-banner-type-list-item:hover {
    font-weight: 600;
}

.product-detail-banner-type-list-item.active:hover {
    font-weight: 400;
}
.product-detail-banner-type-list-item.active {
    /* color: var(--bg-emphasis-color-2); */
    /* new */
    color: var(--text-secondary-color);
}


/* ///////////////////////////// SUBCATEGORY TABS ///////////////////////////// */
  
.product-detail-subcategory-tabs {
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
}
  
.product-detail-subcategory-tabs button {
    padding-inline:  1rem;
    padding-top: 0.5rem;
    padding-bottom: calc(2px + 0.5rem);
    border: none;
    border-radius: 0;
    background: none;
    cursor: pointer;
    color: var(--text-color);
    transition: font-weight 0.3s ease;
}
.product-detail-subcategory-tabs button:hover {
    padding: 0.5rem 1rem;
    border-bottom: 2px solid var(--primary-color);
}

.product-detail-subcategory-tabs button.active {
    padding: 0.5rem 1rem;
    border-bottom: 2px solid var(--border-color);
    font-weight: bold;
}
  
/* ///////////////////////////// PRODUCT INFO ///////////////////////////// */

.product-detail-product-info {
    padding: 2rem;
    background-color: var(--bg-emphasis-color);
    border-radius: var(--border-radius-large);
}

.product-detail-row-item svg {
    fill: var(--primary-color);
}

/* .product-detail-row-item ul {   
    padding-left: 1rem;
} */
/* new */
.product-detail-row-item ul {   
    padding-left: 0;
}
.product-detail-row-item li {
    list-style: none;
}

/* .subtitle {
    font-family: "FiraSans";
} */

.product-detail-product-info p,
.product-detail-product-info li {
    margin-block: 0;
    font-size: var(--font-size-small);
    font-weight: 400;
}

.product-detail-product-info ul {
    margin: 0;
}

.product-detail-characteristics {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2rem 0;
}

.product-detail-characteristics svg {
    fill: var(--text-secondary-color);
}
  
.product-detail-row-item {
    display: flex;
    gap: 1rem;
}
  

/* ///////////////////////////// PRODUCT TABLES ///////////////////////////// */
.product-detail-product-tables {
    margin: 2rem 0;
}
  
.product-detail-category-section {
    margin-bottom: 2rem;
}

.product-detail-category-section h4 {
    font-size: var(--font-size-medium);
    text-transform: uppercase;
    font-weight: 400;
}

.product-detail-category-section tr:hover {
    background-color: var(--bg-emphasis-color);
}
  
.product-detail-product-tables table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    table-layout: fixed;
}

.product-detail-product-tables thead th {
    font-weight: 500;
}


.product-detail-product-tables th,
.product-detail-product-tables td {
    padding: 0;
    border-bottom: 1px solid var(--border-color);
    font-size: var(--font-size-small);
    text-align: left;
}

/* Définition des largeurs fixes pour chaque colonne */
.product-detail-product-tables th:nth-child(1),
.product-detail-product-tables td:nth-child(1) {
    width: auto;
    padding: 0;
}

.product-detail-product-tables th:nth-child(2),
.product-detail-product-tables td:nth-child(2) {
    width:auto;
}

.product-detail-product-tables th:nth-child(3),
.product-detail-product-tables td:nth-child(3) {
    width: auto;
}

.product-detail-product-tables th:nth-child(4),
.product-detail-product-tables td:nth-child(4) {
    width: auto;
    text-align: center;
}

.product-detail-product-tables td {
    height: 2.5rem;
}

/* ///////////////////////////// QUANTITY ROW ///////////////////////////// */

.product-detail-product-tables .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.product-detail-product-tables svg {
    width: 1.2rem;
    height: 1.2rem;
}



/* ///////////////////////////// TOTAL FOOTER ///////////////////////////// */

.product-detail-total-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    
    z-index: 101;
    border-top: 1px solid var(--border-color);
    margin: 0;
    margin-inline: 0 !important;
    padding-inline: var(--spacing-medium);
}

.product-detail-total-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.product-detail-total-section {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.product-detail-total-buttons {
    display: flex;
    gap: 1rem;
}

.product-detail-total-buttons a {
    text-decoration: underline;
}

.product-detail-total-buttons a:hover {
    cursor: pointer;
}

/* ///////////////////////////// MEDIA QUERIES ///////////////////////////// */

@media (max-width: 1200px) {
    .ProductDetailPage section {
        margin-inline: var(--spacing-medium);
    }
}


@media (max-width: 780px) {
    .product-detail-subcategory-tabs {
        overflow-x: auto;
        white-space: nowrap;
    }
}


@media (max-width: 480px) {
    .product-detail-product-tables td {
        text-align: center;
    }
    .sortable-header {
        font-size: var(--font-size-tiny);
    }
    .product-detail-product-tables th:nth-child(1),
    .product-detail-product-tables td:nth-child(1) {
        width: 42px;
    }
    
    .product-detail-product-tables th:nth-child(2),
    .product-detail-product-tables td:nth-child(2),
    .product-detail-product-tables th:nth-child(3),
    .product-detail-product-tables td:nth-child(3) {
        width: auto;
    }
    
    .product-detail-product-tables th:nth-child(4),
    .product-detail-product-tables td:nth-child(4) {
        width: 100px;
    }
    
    .ProductDetailPage section {
        margin-inline: var(--spacing-tiny);
    }   
    .product-detail-product-type-banner h1 {
        font-size: var(--font-size-large);
    }
}    
@media (max-width: 360px) {
    .product-detail-product-type-banner h1 {
        font-size: var(--font-size-medium);
    }
}    

/* Classe pour désactiver la position fixed quand on atteint le footer */
.product-detail-total-footer.absolute {
    position: absolute;
    display: none;
    margin-bottom: var(--spacing-large);
}
    
.show-more-button-container {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
}

.show-more-button {
    border-color: var(--text-secondary-color);
    color: var(--text-secondary-color);
}

.sortable-header {
  cursor: pointer;
  user-select: none;
  align-items: center;
}

.sortable-header:hover {
  color: var(--primary-color);
}

.product-detail-product-tables th svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
