.product-title {
    align-self: flex-start;
    margin-top: var(--spacing-xl);
}

.products-grid {
    display: flex;
    max-width: 1300px;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-large);
    row-gap: var(--spacing-medium);
    margin-top: var(--spacing-large);
    margin-inline: auto;
}

.products-grid > * {
    flex: 1 1 calc(50% - var(--spacing-large));
    max-width: calc(50% - var(--spacing-large));
    box-sizing: border-box;
}

.products-grid:has(> :nth-child(odd):only-child) > :only-child {
    flex: 1 1 calc(50% - var(--spacing-large));
    max-width: calc(50% - var(--spacing-large));
}

.product-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--padding-small);
    max-width: 12rem;
    text-decoration: none;
}

.product-image-container {
    overflow: hidden;
    width: 100%;
    border-radius: 8%;
}

.product-card img {
    display: block;
    width: 100%;
    max-height: 14rem;
    max-width: 14rem;
    aspect-ratio: 1;
    border-radius: 8%;
    object-fit: cover;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.product-card:hover img {
    transform: scale(1.05);
    /* Add a white filter */
    filter: brightness(1.1);
}

.product-card p {
    margin: 0;
    font-size: var(--font-size-medium);
    color: var(--text-color);
    /* new c'est moche */
    text-align: center;
    font-weight: 600;
    display: block;
    width: 100%;
    transition: color 0.3s ease;
}


.product-card:hover p {
    color: var(--primary-color) !important;
}

@media (max-width: 1130px) {
    .product-card {    
        max-width: 10rem;
    }
    .product-card img {    
        max-height: 10rem;
        max-width: 10rem;
    }

}

@media (max-width: 768px) {
    .products-grid {
        width: 100%;
    }
}

@media (max-width: 380px) {
    .products-grid {
        gap: var(--spacing-tiny);
    }
}

@media (max-width: 380px) {
    .product-card img {
        width: 100%;
        max-height: 10rem;
        max-width: 10rem;
    }
    .product-image-container {
        max-height: 10rem;
        max-width: 10rem;
    }
}

@media (max-width: 350px) {
    .product-card img {
        width: 100%;
        max-height: 9rem;
        max-width: 9rem;
    }
    .product-image-container {
        max-height: 9rem;
        max-width: 9rem;
    }
}

@media (max-width: 320px) {
    .product-card img {
        width: 100%;
        max-height: 8rem;
        max-width: 8rem;
    }
    .product-image-container {
        max-height: 8rem;
        max-width: 8rem;
    }
} 