
/* ////////////////////// ADMIN SECTION STYLE ////////////////////*/

.my-account-section {
  margin: var(--spacing-xxl) 0;
}

.my-account-section .my-account-admin-subsection {
  margin-bottom: var(--spacing-large);
}

.my-account-admin-buttons {
  margin-top: var(--spacing-large);
  display: flex;
  justify-content: flex-end;
}

.my-account-admin-buttons button {
  margin-left: var(--spacing-medium);
}

.my-account-admin-subsection h3 {
  margin-bottom: var(--spacing-large);
}

/* ////////////////////// ESTIMATES TABLE STYLE ////////////////////*/

.my-account-estimates-table-container {
  margin-top: var(--spacing-large);
  background-color: var(--bg-emphasis-color);
  border-radius: var(--border-radius-large);
  padding: var(--spacing-large);
  overflow-x: auto;
}

.my-account-estimates-table {
  width: 100%;
  border-collapse: collapse;
  /* min-width: 600px; */
}

.my-account-estimates-table tr {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.my-account-estimates-table tr:hover {
  background-color: var(--bg-emphasis-color-2);
}

.my-account-estimates-table th {
  cursor: default;
  text-align: left;
  padding: var(--padding-medium);
  border-bottom: 2px solid var(--border-color);
  color: var(--text-color);
  font-weight: bold;
}

.my-account-estimates-table thead tr:hover {
  background-color: transparent;
}


.my-account-estimates-table td {
  padding: var(--padding-small);
  border-bottom: 1px solid var(--border-secondary-color);
}

.my-account-date-header {
  display: flex;
  align-items: center;
  gap: var(--padding-small);
  cursor: pointer;
}

.my-account-sort-icon {
  color: var(--text-secondary-color);
}


.myaccount-create-new-estimate {
  display: flex;
  justify-content: right;
  margin-top: var(--spacing-medium);
}

/* ////////////////////// LOGOUT SECTION STYLE ////////////////////*/

.my-account-logout-section {
  margin-top: var(--spacing-xxl);
  display: flex;
  justify-content: flex-end;
}

/* ////////////////////// MEDIA QUERIES ////////////////////*/

@media (max-width: 480px) {
  .my-account-estimates-table-container {
    padding: 0;
    background-color: transparent;
  }
}
