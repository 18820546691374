.EstimatePage main {
  margin-top: var(--spacing-xxl);
  padding-inline: var(--spacing-small);
  padding-top: var(--spacing-tiny);
}


.EstimatePage {
  background-color: #f8f8f8;
}

.commercial-estimate .estimate-product-container:nth-child(even):hover {
  background-color: var(--bg-emphasis-color);
  cursor: default;
}
.commercial-estimate .estimate-product-container:nth-child(odd):hover {
  background-color: var(--bg-color);
  cursor: default;
}

.estimate-owner-info {
  padding: 1.5rem 2rem;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  
}

.commercial-estimate-title-header {
  display: flex;
  margin-bottom: var(--spacing-medium);
} 

.commercial-estimate-title-header p {
  font-size: var(--font-size-medium);
  margin: 0;
}
  
.owner-info p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

.owner-name {
  font-weight: bold;
}

p.owner-regime {
  margin-bottom: 1rem !important;
  color: var(--text-secondary-color);
  /* font-style: italic; */
  font-size: var(--font-size-small);
}

.estimate-owner-info-item {
  display: flex;
  gap: 0.5rem;

}