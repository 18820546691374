.EntreprisePage section {
    margin-top: 5rem;
    margin-bottom: 7rem;
}
.EntreprisePage main {
    padding-bottom: 0;
    
}
.EntreprisePage {
    background-color: #f8f8f8;
}

.history-milestone {
    margin-bottom: var(--spacing-xl);
    position: relative;

    display: flex;
}

.year {
    color: var(--primary-color);
    font-size: var(--font-size-large);
    font-weight: bold;
    text-align: center;
    margin: var(--spacing-large) 0;
}

.historic-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin-right: 1rem;
}

.historic-text p {
    margin: 0;
}

.historic-image {
    /* margin: var(--spacing-xl) 0; */
    text-align: center;
}

.historic-image img {
    max-width: 100%;
    height: auto;
}

.history-button {
    text-decoration: underline;
    margin-top: 1rem;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    margin-top: var(--spacing-xl);
}

.stat-item {
    text-align: center;
    /* padding: var(--spacing-medium); */
    /* background-color: var(--bg-emphasis-color); */
    border-radius: var(--border-radius);
    transition: transform 0.3s ease;
}

.stat-item svg {
    margin-inline: auto;
    width: 3rem;
    height: 3rem;
    fill: var(--text-secondary-color);
}

/* .stat-item:hover {
    transform: translateY(-5px);
} */

.stat-value {
    color: var(--primary-color);
    /* font-size: 2.5rem; */
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.stat-label {
    color: var(--text-color);
    font-size: var(--font-size-small);
}

@media (max-width: 768px) {
    .stats-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    
    .historic-text {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .history-milestone {
        display: block;
    }
}

@media (max-width: 480px) {
    .year {
        font-size: var(--font-size-xl);
    }
    
    .stat-value {
        font-size: 2.5rem;
    }
    
    .stat-label {
        font-size: var(--font-size-tiny);
    }
} 