.footer2 {
    background-color: var(--dark-bg-color);
    padding-block: 6rem;
    padding-inline: 4rem;
}

.footer2 h3 {
    margin: 0;
}

.footer2 p {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: var(--font-size-small);
}

.footer2 a {
    font-size: var(--font-size-small);
}

a.footer2-email,
a.footer2-tel {
    display: block;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0.5rem;
    color: var(--bg-emphasis-color-2);
    transition: all 0.3s ease-out;
}
a.footer2-email:hover,
a.footer2-tel:hover {
    color: var(--light-chataigne);
    cursor: pointer;
    
}


.footer2-content {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.footer2-logo {
    height: 2rem;
    width: auto;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 2.5rem;
}

.footer-contact-schedule {
    margin-top: 0.5rem;
}
.footer-contact-button {
    margin-top: 2rem;
    width: 100px;
}

button.footer-button {
    background-color: transparent;
    border: 1px solid var(--bg-color);
    width: auto;
}
button.footer-button:hover {
    background-color: #ffffff10;
}

/* ////////////////////////////////////////////////// */


.footer2-nav a {
    display: block;
    margin-bottom: 1rem;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.footer2-nav a:hover {
    opacity: 0.8;
}

.footer-nav-espace-client {
    color: var(--bg-emphasis-color-2);
}

/* ////////////////////////////////////////////////// */


.newsletter-section {
    margin-bottom: 2rem;
    text-align: right;
}

.newsletter-section h3 {
    margin: 0;
}

.newsletter-form {
    text-align: right;
}

.input-group {
    position: relative;
    width: 100%;
}

.newsletter-form input[type="email"] {
    padding: 0.8rem;
    border: none;
    border-radius: 4px;
    font-size: var(--font-size-small) !important;
    width: 100%;
    background-color: var(--bg-color);
    color: var(--text-color);

    margin-block: 1rem;
}

.error-message {
    color: #ff4444;
    font-size: 0.8rem;
    position: absolute;
    bottom: -1.2rem;
    right: 0;
}

/* Custom Checkbox Styles */
.custom-checkbox-group {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0.5rem 0;
    margin-bottom: 1rem;
    width: auto;
}

.custom-checkbox {
    text-align: right;
    position: relative;
    padding-left: 1.4rem;
    cursor: pointer;
    font-size: 0.8rem;
    user-select: none;
    color: var(--bg-color);
    width: auto;
}

.custom-checkbox input {
    display: inline;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark-box {
    position: relative;
    top:  0.25rem;
    display: inline-block;
    margin-right: 0.5rem;
    height: 1.2rem;
    width: 1.2rem;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    border: 2px solid var(--bg-color);
    border-radius: 3px;
    transition: all 0.2s ease;

    height: 1.2rem;
    width: 1.2rem;
    z-index: 100;
}

.checkmark.checked {
    background-color: var(--bg-color);
}

.custom-checkbox:hover input ~ .checkmark {
    background-color: rgba(255, 255, 255, 0.1);
}


.checkmark-after {
    content: "";
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    height: 0.75rem;
    width: 0.5rem;
    border: solid var(--dark-bg-color);
    border-width: 0 2px 2px 0;
    z-index: 101;
    transform: translate(-50%, -50%) rotate(45deg);
}

.custom-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-label {
    display: inline;
    width: auto;

}

.checkbox-label a {
    display: inline-block;
    color: var(--bg-color);
    font-weight: 600;
    text-decoration: none;
    font-size: 0.8rem;
}

.checkbox-label a:hover {
    text-decoration: underline;
}

/* Button Styles */
/* .footer-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.footer-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
} */

.map-container {
    flex-shrink: 0;
    width: 100%;
    height: auto;
}

.map-container .google-map > iframe {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-small);
}


/* Responsive design */
@media (max-width: 800px) {
    .footer2-content {
        display: block;
    }

    .footer2-column {
        display: block;
        text-align: center;
        align-items: center;
        width: 100%;
        margin-bottom: 5rem;
    }
    .footer2-column:last-child {
        margin-bottom: 0;
    }

    .newsletter-form {
        width: auto;
        max-width: 400px;
        margin-left: auto;
    }
    .footer2-nav {
        text-align: center;
    }

    
}