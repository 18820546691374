.EstimatePage main {
  margin-top: var(--spacing-xxl);
  padding-inline: var(--spacing-small);
  padding-top: var(--spacing-tiny);
}

.EstimatePage {
  background-color: #f8f8f8;
}

.estimate-section {
  max-width: 1200px;
  margin: 0 auto;
}

/* ///////////////////////////// HEADER ///////////////////////////// */

.my-account-estimate-link a {
  text-decoration: underline;
  color: var(--text-color);
  font-size: var(--font-size-small);
}
.my-account-estimate-link span { 
  font-size: var(--font-size-small);
}



.estimate-section .my-account-estimate-back-link {
  cursor: pointer;
}

.estimate-section .my-account-estimate-back-link svg {
  fill: var(--primary-color);
}

.estimate-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: var(--spacing-medium);
}

.estimate-title-header {
  display: flex;
  align-items: center;
  margin-block: var(--spacing-medium);
  width: 100%;
  gap: var(--spacing-small);
}

.estimate-title-header p {
  color: var(--secondary-color);
  font-size: var(--font-size-large);
  margin: 0;
}

.estimate-title-header .icon-button svg,
.estimate-header .icon-button svg {   
    fill: var(--primary-color);
}

/* ///////////////////////////// CONTENT ///////////////////////////// */

.estimate-content {
  background-color: var(--bg-color);
  border-radius: var(--border-radius-small);
  padding: var(--spacing-tiny);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* ///////////////////////////// TITLE ///////////////////////////// */

.editable-estimate-title {
  height: auto;
  background-color: transparent;
  padding: 0;
  margin-right: var(--spacing-tiny);
}

.editable-estimate-title:hover {
  background-color: transparent;
}

input.editable-estimate-title {
  padding: var(--padding-small);
  border-color: var(--primary-color);
  margin-inline: 0;
  background-color: transparent;
}

input.editable-estimate-title:focus {
  border-color: var(--primary-color);
}

.editable-estimate-title-container {
  width: 100%;
  margin-inline: var(--spacing-tiny);
}

.editable-estimate-title-container-with-button {
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--border-color);
  margin-left: var(--spacing-tiny);
}

.estimate-content-description {
  margin-inline: var(--spacing-tiny);
  font-size: var(--font-size-small);
}

/* ///////////////////////////// TABLE ///////////////////////////// */


.estimate-product-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--bg-color);
  padding: var(--spacing-tiny);
  transition: background-color 0.2s ease;
  font-size: var(--font-size-small);
}
.estimate-product-container p {
  margin: 0;
}

.estimate-quantity-price {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: var(--text-secondary-color);

  margin-top: var(--spacing-tiny);
}

.estimate-product-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-tiny);
}

.estimate-product-header svg {
  fill: var(--error-color);
}

.estimate-quantity-price p {
  margin: 0;
}

.estimate-product-container:nth-child(even) {
  background-color: var(--bg-emphasis-color);
}

.estimate-product-container:nth-child(odd):hover {
  background-color: var(--bg-emphasis-color);
  cursor: pointer;
}

.estimate-product-container:nth-child(even):hover {
  background-color: var(--bg-emphasis-color-2);
  cursor: pointer;
}

.estimate-footer {
    padding: var(--spacing-tiny);
}

.estimate-total {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 1rem;
    color: var(--secondary-color);
}

.estimate-save-text {
  color: var(--text-secondary-color);
  font-size: var(--font-size-small);
  text-align: right;
  margin-top: var(--spacing-tiny);
}

.create-new-estimate-btn {
  border: none;
  padding-left: 0;
  
}
.create-new-estimate-btn:hover {
  background-color: transparent;
  color: var(--text-color);
}

/* ///////////////////////////// MODAL ///////////////////////////// */

.estimate-item-modal-overlay .modal-content {
    width: auto;
    height: auto;
}

.estimate-item-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-small);
}

.estimate-item-modal-content {
  background-color: var(--bg-emphasis-color);
  padding: var(--spacing-tiny);
  border-radius: var(--border-radius-small);
}

/* ///////////////////////////// ADD PRODUCTS BUTTON ///////////////////////////// */

.estimate-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 1rem;
}

@media (max-width: 792px) {
    .estimate-actions {
        flex-direction: column-reverse;
        margin-top: var(--spacing-xl);
    }
    .create-new-estimate-btn {
      padding-right: 0;
    }
}