.legal-notice-section, .privacy-policy-section {
    width: 70%;
    margin-bottom: var(--spacing-xl);
}

.legal-notice-section p, 
.legal-notice-section li,
.privacy-policy-section p,
.privacy-policy-section li {
    font-size: var(--font-size-small);
}

.legal-notice-section h2,
.privacy-policy-section h2 {
    margin-top: var(--spacing-xl);
}



@media (max-width: 550px) {
    .legal-notice-section {
      width: 90%;
    }
    .privacy-policy-section {
      width: 90%;
    }
  }
  