.contact-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* gap: 2rem; */
  margin: 2rem 0;
}

.contact-info-item-line {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: inherit;
  margin-top: var(--padding-medium);
}

.contact-info-item-line svg {
  flex-shrink: 0;
}

.contact-info-item-line p {
  margin: 0;
  font-size: 1.4rem;
} 
