.footer-captcha {
  margin-right: 0;
  margin-left: auto;
  width: auto;
  margin-bottom: 1rem;
}

.footer-captcha div {
  margin: 0 !important;
  padding: 0 !important;
  width: auto !important;
}