/* ServicesSection.css */

.services-grid {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); */
    grid-template-columns: repeat(2, auto);
    gap: 30px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .service-card {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-image-container {
    width: 100%;
    height: 240px; /* Image assez grande */
    overflow: hidden;
  }
  
  .service-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .service-card:hover .service-image-container img {
    transform: scale(1.05);
  }
  
  .service-content {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .service-content h4 {
    margin: 0 0 15px 0;
    color: var(--header-text-color);
    font-size: 1.3rem;
    text-align: center;
  }
  
  .service-content h4 span {
    position: relative;
    padding-bottom: 8px;
  }
  
  .service-content h4 span:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--secondary-color); /* Couleur verte qui s'accorde bien avec un thème bois */
    transform: scaleX(0.6);
    transition: transform 0.3s ease;
  }
  
  .service-card:hover .service-content h4 span:after {
    transform: scaleX(1);
  }
  
  .service-content p {
    font-size: var(--font-size-small);
    margin: 0;
    margin-top: 1rem;
    flex-grow: 1;
  }
  
  /* /////////////////////// Styles pour la modal /////////////////////// */
  .service-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .service-modal {
    background-color: var(--bg-color);
    border-radius: 8px;
    max-width: 700px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    animation: modalFadeIn 0.3s ease;
  }
  
  .service-modal-content {
    padding: 30px;
  }
  
  .service-modal-content h3 {
    margin: 0 0 15px 0;
    color: var(--header-text-color);
    font-size: 1.8rem;
  }
  
  .service-modal-content hr {
    border: none;
    height: 2px;
    background-color: var(--border-secondary-color);
    margin: 15px auto 25px;
    width: 100%;
  }

  .service-modal-content p {
    color: var(--header-text-color);
  }

  .service-modal-content li {
    color: var(--secondary-color);
  }
  
  .service-modal-content span {
    display: block;
    margin-bottom: 10px;
  }
  
  /* Animation pour la modal */
  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
    }
    
    .service-image-container {
      height: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .services-grid {
      grid-template-columns: 1fr;
    }
    
    .service-image-container {
      height: 220px;
    }
  }