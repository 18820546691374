.rse-title {
    align-self: flex-start;
    margin-top: var(--spacing-xl);
}

/*//////////////////// rse GRID ////////////////////*/

.rse-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--spacing-large);
    row-gap: var(--spacing-medium);
    /* margin-top: var(--spacing-large); */
}

.rse-grid > * {
    flex: 1 1 calc(50% - var(--spacing-large));
    max-width: calc(50% - var(--spacing-large));
    box-sizing: border-box;
}

/*//////////////////// rse CARD ////////////////////*/

.rse-card {
    display: flex;
    gap: var(--spacing-medium);
    padding: var(--padding-medium) var(--spacing-medium);
    border-radius: var(--border-radius-large);
    background-color: var(--bg-color);
    /* box-shadow: var(--shadow-small); */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.rse-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-small);
    cursor: pointer;
}

.rse-image-container {
    height: auto;
    margin: auto;
    overflow: hidden;
    border-radius: var(--border-radius-small);
}
.rse-image-container svg {
    
    margin-inline: auto;
    width: 2rem;
    height: 2rem;
    fill: var(--dark-chataigne);
}

.rse-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* new */
    /* gap: var(--spacing-tiny); */
    gap: var(--padding-tiny);
}

/*//////////////////// rse CARD TITLE ////////////////////*/

.rse-content h4 {
    margin: 0;
    font-size: var(--font-size-medium);
    font-weight: 600;
    transition: color 0.1s ease-out;
}


.rse-card:hover h4 {
    color: var(--primary-color);
}

/* /////////////////////////// RSE MODAL ////////////////////////*/

.rse-content p {
    margin: 0;
    font-size: var(--font-size-small);
    color: var(--text-color);
    line-height: 1.4;
}

.rse-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rse-modal {
    background-color: var(--bg-color);
    padding: 2rem;
    border-radius: 8px;
    position: relative;
    max-width: 600px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
}

.rse-modal-content-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.rse-modal-content-title h3 {
    margin: 0;
}

.rse-modal-content-text {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
}


@media (max-width: 768px) {
    .rse-grid {
        width: 100%;
    }
    
    .rse-grid > * {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .rse-modal {
        width: 95%;
        padding: 1.5rem;
    }
}

@media (max-width: 480px) {

} 