
.loading-button:disabled {
    background-color: var(--secondary-color);
    cursor: not-allowed;
}
  
.secondary-button.loading-button:disabled {
    background-color: var(--bg-emphasis-color);
}
  
.loading-button-text.loading {
    color: var(--bg-emphasis-color);
}

.loading-button .spinner {
    border: 0.15rem solid transparent;
    border-top: 0.15rem solid var(--primary-color);
  }
  
.loading-button .spinner.on-primary {
    border: 0.15rem solid transparent;
    border-top: 0.15rem solid var(--bg-color);
  }