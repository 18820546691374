.HistoirePage main {
  margin: 0 auto;
}

.timeline {
  position: relative;
  padding: 2rem 0;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: var(--primary-color);
}

.timeline-item {
  display: flex;
  justify-content: center;
  padding-block: 3rem;
  position: relative;
}

.year {
  background-color: var(--primary-color);
  color: var(--bg-color);
  padding: 0.5rem 1rem;
  border-top-right-radius: var(--border-radius-tiny);
  border-bottom-right-radius: var(--border-radius-tiny);
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
}


.historic-year-content h3 {
  color: var(--text-color);
  margin-bottom: 1rem;
}

.timeline-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 1rem;
}

.timeline::before {
  left: 0;
}

.timeline-item {
  flex-direction: column;
}

.timeline-item .historic-year-content {
  width: 100%;
  text-align: left !important;
  order: 1 !important;
  padding-top: 5rem;
  padding-left: 4rem;
}
