/*//////////////////// HOMEPAGE MAIN STYLE ////////////////////*/

.HomePage {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.HomePage section {
  /* margin-block: var(--spacing-xxl); */
  margin-bottom: var(--spacing-xxxl);
  margin-inline: var(--spacing-xxl);
}

.HomePage main {
  margin: 0;
  padding: 0;
  max-width: 100%;
}

/*//////////////////// MAIN SECTION STYLE ////////////////////*/
.home-title-section {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxxl);
}

.home-title-section h1 {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.home-title-section h2 {
  font-size: 1rem;
  margin-top: 0.5rem;

}

.home-news-section {
  background-color: var(--bg-color);
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.home-news-section-content {
  padding: 0;
  padding-top: var(--spacing-xxl);
}

@media (max-width: 1200px) {
  .HomePage section {
    margin-inline: var(--spacing-xl);
  }
}

@media (max-width: 480px) {
  .HomePage section {
    margin-inline: var(--spacing-small);
  }
}
