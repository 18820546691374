/*//////////////////// SERVICEPAGE MAIN STYLE ////////////////////*/
.ServicePage section {
  margin-block: var(--spacing-xxl);
}
.ReferencePage main {
  padding-inline: 0;
  max-width: 100%;
}

.references-section {
  margin-block: var(--spacing-large);
}

.ReferencePage .title-with-hr-container {
  padding-inline: var(--spacing-medium);
}

/* .ReferencePage {
  
  background-color: var(--bg-emphasis-color);
} */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
}